
  import {defineComponent} from 'vue'
  import service from "@/service";


  export default defineComponent({
    name: 'ListView',
    data() {
      return {
        articles: {
          data: [],
          page: 1,
          total: 0,
          pageSize: 50,
        },
        isMore: true,
        top: {
          data: [],
          page: 1,
          pageSize: 10,
        },
        imgUrl: process.env.VUE_APP_IMG_URL,
        categoryId: this.$route.query.categoryId,
      }
    },
    mounted() {

      this.getArtiles();
      this.getTop();

      window.addEventListener('scroll', this.handleScroll, true);

    },
    methods: {
      handleScroll: function (e) {
        console.log("scrolllllllll");

        //变量scrollTop是滚动条滚动时，距离顶部的距离
        var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        //变量windowHeight是可视区的高度
        var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
        //变量scrollHeight是滚动条的总高度
        var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        //console.log(scrollHeight + " " + scrollTop +" "+ windowHeight);

        //滚动条到底部的条件
        if (scrollTop + windowHeight == scrollHeight && this.articles.data.length !== 0) {
          console.log("crollinggggggggggggg");
          this.articles.page++;
          this.getArtiles();
        }

      },
      getArtiles: function () {
        service.getArtiles({
          page: this.articles.page,
          pageSize: this.articles.pageSize,
          categoryId: this.categoryId,
        }).then((res: any) => {

          console.log("getArtiles result");
          console.log(res);

          if (res.data.length <= 0) {
              console.log("没有更多了");
              this.isMore = false;
          } else {
            console.log("before");
            this.articles.data = this.articles.data.concat(res.data);
          }

        })
      },
      getTop: function () {
        service.getTop({
          page: this.top.page,
          pageSize: this.top.pageSize,
          categoryId: this.categoryId
        }).then(res => {
          // console.log(res);
          this.top.data = res.data;
        });
      }


    },
    destroyed: function () {
      window.removeEventListener('scroll', this.handleScroll);
    },
    watch: {
      /*search() {
        this.getArtiles();
      },
      */
    }
  })
