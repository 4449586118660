
  import {defineComponent} from 'vue'
  import service from "@/service";


  export default defineComponent({
    name: 'Search',
    data() {
      return {
        articles: {
          data: [],
          page: 1,
          total: 0,
          pageSize: 50,
          loadingOver: true
        },
        top: {
          data: [],
          page: 1,
          pageSize: 10,
        },
        imgUrl: process.env.VUE_APP_IMG_URL,
        categoryId: this.$route.query.categoryId,
        //s: this.$route.query.s
      }
    },
    mounted() {

      this.getTop();


      window.addEventListener('scroll', this.listScroll);
      // window.addEventListener('scroll', this.handleScroll)
    },
    methods: {
      listScroll: function () {

        /**
         const el:any = this.$refs.myOrderListWrapper;

         //document.querySelector()
         let scrollTop = window.document.documentElement.scrollTop || window.document.body.scrollTop;
         let h = window.innerHeight || window.document.documentElement.clientHeight || window.document.body.clientHeight; //屏幕的高度
         let prodListHeight = el.offsetHeight-h-20;  //.myOrderListWrapper 商品列表容器
         console.log(scrollTop,":::",prodListHeight);

         if(scrollTop > prodListHeight && this.articles.loadingOver){
          this.articles.page = this.articles.page + 1;
          this.getArtiles();
        }
         */
      },
      getSearch: function (keywords) {

        console.log("input keywords:");
        console.log(keywords);

        service.getArtiles({
          page: this.articles.page,
          pageSize: this.articles.pageSize,
          categoryId: this.categoryId,
          s: keywords
        }).then((res: any) => {

          console.log("getSearch result");
          console.log(res);

          this.articles.data = res.data;

          //console.log(this.articles.data)
          //this.articles.data.push(res.data);
          //console.log(this.articles.data)

          /**

           //有数据的时候加载
           if(res.data.length > 0){
            this.articles.loadingOver = true;
           // this.articles.data.push(res.data as never);
           // this.articles.data.push(res.data);

            //console.log(res.data)

            this.articles.data = res.data
          }else{
            this.articles.loadingOver = false
          }

           console.log("=====end=====");
           console.log( this.articles.data)
           */
        })
      },
      getTop: function () {
        service.getTop({
          page: this.top.page,
          pageSize: this.top.pageSize,
          categoryId: this.categoryId
        }).then(res => {
          // console.log(res);
          this.top.data = res.data;
        });
      }


    },
    computed: {
      myKw: {
        get() {
          //从搜索框中取kw参数
          let {kw} = this.$route.params;
          console.log("----search get----");
          console.log(kw);
          this.getSearch(kw);
          return kw
        },
        set(val) {
          console.log("----search set----");
          //路由跳转传递kw参数
          //直接给路由地址栏加内容最好方便操作，比操作数据方便
          this.$router.push({
            name: 'search',
            params: {
              kw: val
            }
          });
        }
      }
    },
    destroyed: function () {
      window.removeEventListener('scroll', this.listScroll);
    },
    watch: {
      /*search() {
        this.getArtiles();
      },
      */
    }
  })
