//引入封装好的 service
import service from "@/service/axios";

//设置请求头（如果请求头统一的话可以在axios文件设置，则无须从这里传过去）
const header = {
  Accept: 'application/json;charset=UTF-8',
};

//导出
export default {
  getMenu(data) {
    return service.get('/v1/getCategories', data, header);
  },
  getArtiles(data) {
    console.log("axios入参");
    console.log(data);
    return service.get('/v1/getArticles', data, header);
  },
  getArtile(data) {
    return service.get('/v1/getArticle', data, header);
  },
  getTop(data) {
    return service.get('/v1/getTop', data, header);
  }
};
