
  import {defineComponent} from 'vue'
  import service from "@/service";

  export default defineComponent({
    name: 'HomeView',
    data() {
      return {
        top: {
          data: [],
          page: 1,
          pageSize: 10
        },
      }
    },
    mounted() {

      this.getTop();


      // window.addEventListener('scroll', this.handleScroll)
    },
    methods: {
      getTop: function () {
        service.getTop({
          page: this.top.page,
          pageSize: this.top.pageSize,
          categoryId: 3
        }).then(res => {
          console.log(res);
          this.top.data = res.data;
        });
      }
    }

  })
