
  import {defineComponent} from 'vue'
  import GuoCangHeader from '@/components/GuoCangHeader.vue' // @ is an alias to /src
  import GuoCangFooter from '@/components/GuoCangFooter.vue' // @ is an alias to /src
  import service from "@/service";

  export default defineComponent({
    name: 'InfoView',
    components: {
      GuoCangHeader,
      GuoCangFooter
    },
    data() {
      return {
        article: {},
      }
    },
    mounted() {
      service.getArtile({id: this.$route.query.id}).then(res => {
        this.article = res.data;
        document.title = res.data.title;
       // document. = res.data.title;
       /* let seo = {
          title: res.data.title,
          description: res.data.description,
          keywords: res.data.keywords
        };
        this.$emit('seo',seo)*/
      });

    },
  })
