
  import {defineComponent} from 'vue'
  import service from "@/service";

  export default defineComponent({
    name: 'GuoCangHeader',
    props: {
      msg: String
    },
    data() {
      return {
        article: {},
        categoryId: JSON.stringify(this.$route.query) == "{}" ? 0 : this.$route.query.categoryId,
        keywords: ''
      }
    },
    mounted() {
      //console.log(JSON.stringify(this.$route.query),this.categoryId);

     // service.getMenu({page: 1, pageSize: 1000}).then(res => {

       // console.log("=====get menus=====");
       // console.log(res);
        // let d = eval('(' + res.data + ')');
        // this.menus = res.data;
     // });



    },
    methods: {
      btnFind:function(kwContent) {
        console.log("header search keywords:");
        console.log(kwContent);
        if (!kwContent) {
          return;
        } else {
          //console.log('搜索内容',kwContent);
          //路由跳转并传递参数
          this.$router.push({
            name: 'search',
            params: {
              kw: kwContent
            }
          });
        }
      }


    },

  })
