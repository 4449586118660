import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import GuoCangHeader from '@/components/GuoCangHeader.vue' // @ is an alias to /src
import GuoCangFooter from '@/components/GuoCangFooter.vue' // @ is an alias to /src


//import axios from 'axios'
//import VueAxios from 'vue-axios'


createApp(App)
  .component('GuoCangHeader',GuoCangHeader)
  .component('GuoCangFooter',GuoCangFooter)
  .use(store)
  .use(router)
  .mount('#app');
//createApp(App).use(store).use(router).use(VueAxios, axios).mount('#app')
