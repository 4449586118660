import axios from "axios";

const instance = axios.create({
  timeout: 1800000,//数据响应过期时间
  baseURL:process.env.VUE_APP_API_URL,
});

//请求拦截器
instance.interceptors.request.use((config) => {
  //在发送之前做点什么
  return config
}, (error) => {
  //对请求错误做点什么
  return error
})

//响应拦截器
instance.interceptors.response.use((response) => {
  if (response.status === 200) {
    return response.data
  } else {
    return {resultCode: -1}
  }
});

//导出
export default {
  get(url, params, headers) {
    return instance.get(url, {params, headers})
  },
  post(url, params, headers) {
    return instance.post(url, params, {headers})
  },
  put(url, params, headers) {
    return instance.put(url, params, {headers})
  },
  delete(url, params, headers) {
    return instance.delete(url, {params, headers})
  }
}
